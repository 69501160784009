<template>
  <div class="contact">
    <div class="top">
      <HightLight bg-text="." :is-bg="true" text="核心团队" class="title"></HightLight>
      <div class="left">
        <div>
          <img style="margin-right: 22px" src="https://www.ubetterplus.com/file/user/20240708/17204317709461.png" alt="">
        </div>
        <div class="mes">
          <div style="margin-left: 22px">
            <p>数学竞赛保送北大，多年初中数学教学经验</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import HightLight from "@/components/common/hightLight/HightLight";
  export default {
    name: "Contact",
    components:{
      HightLight
    },
    mounted(){
      
    }
  }
</script>
<style scoped>
  .left{
    display: flex;
    height: 250px;
    justify-content: center;
    align-items: center;
    padding-left: 65px;
    margin-bottom: 50px;
  }
  .left img{
    height: 200px;
    width: 200px;
  }
  .contact{
    display: flex;
    justify-content: center;
  }
  .top{
    justify-content: center;
    width: 1200px;
  }
  .mes{
    margin-left: 10px;
    font-size: 18px;
    color: #666666;
  }
  .title{
    margin-right: 13px;
  }
  .tag{
    text-align: center;
    margin-bottom: 25px;
    font-size: 28px;
    font-weight: bolder;
    color: red;
  }
</style>
